<template>
  <div>
    <!-- Show a menu -->
    <div
      v-if="overview.canUserWrite"
      class="d-flex justify-end mb-4 mt-8 mt-md-12"
    >
      <slot name="action"></slot>

      <v-menu
        offset-y
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            color="primary"
            class="ml-3"
            v-bind="attrs"
            v-on="on"
          >
            Actions

            <v-icon right>expand_more</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            v-if="isInfluencerGroupsAllowed && shouldShowAddToGroup"
            :disabled="isMakingRequest || selectedItems.length === 0"
            @click="shouldShowInfluencerGroupsForm = true"
          >
            <v-list-item-title>
              Copy to Group
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="isCampaignTrackingAllowed && shouldShowAddToCampaign"
            :disabled="isMakingRequest || selectedItems.length === 0"
            @click="shouldShowCampaignTrackingForm = true"
          >
            <v-list-item-title>
              Add to Campaign
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="isSocialSentimentsAllowed && shouldShowSocialSentimentsButton"
            :disabled="isMakingRequest || selectedItems.length <= 1"
            @click="shouldShowSocialSentimentsForm = true"
          >
            <v-list-item-title>
              Compute Sentiments
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="isAudienceOverlapAllowed && shouldShowAudienceOverlapButton"
            :disabled="isMakingRequest || selectedItems.length <= 1"
            @click="shouldShowAudienceOverlapForm = true"
          >
            <v-list-item-title>
              Compute Overlap
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            :disabled="isMakingRequest || selectedItems.length === 0"
            @click="shouldShowDeleteDialog = true"
          >
            <v-list-item-title>
              Remove {{ selectedItems.length > 1 ? "influencers" : "influencer" }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- Show the filters -->
    <v-card class="box-shadow-soft rounded rounded-bl-0 rounded-br-0 px-3 py-6">
      <v-row>
        <!-- Show the selectors -->
        <v-col
          cols="12"
          md="6"
        >
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <platform-selector
                :module="module"
                :overview="overview"
                small
                show-all
                outlined
                full-width
              />
            </v-col>

            <v-col
              v-if="shouldShowUnpublishedFilter"
              cols="12"
              md="4"
            >
              <v-select
                v-model="publishedStatus"
                label="Filter"
                outlined
                dense
                hide-details
                :items="publishedStatusOptions"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-spacer />

        <!-- Show the input -->
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="searchQuery"
            label="Search"
            placeholder="Influencer fullname or username"
            outlined
            dense
            hide-details
            clearable
            append-icon="search"
            @keypress.enter="currentPage = 1"
            @click:append="currentPage = 1"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <!-- Show the table view -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableHeaders"
      :items="tableItems"
      :loading="isMakingRequest"
      :page="currentPage"
      :server-items-length="response.total || 0"
      :items-per-page="perPage"
      @update:page="currentPage = $event"
      @update:items-per-page="perPage = $event"
      class="box-shadow-soft influencers-data-table"
      item-key="id"
      selectable-key="id"
      :show-select="shouldShowSelect"
      :mobile-breakpoint="100"
    >
      <!-- Modify the platform column -->
      <template v-slot:item.platform="{ item }">
        <div>
          <v-img
            :src="`/img/socials/${item.platform}.svg`"
            max-width="32"
            height="32"
          ></v-img>
        </div>
      </template>

      <!-- Modify the influencer column -->
      <template v-slot:item.username="{ item }">
        <profile-chip
          :platform="item.platform"
          :data="item"
        />
      </template>

      <!-- Modify the status column -->
      <template v-slot:item.status="{ item }">
        <status-chip
          :status="item.status"
        />
      </template>

      <!-- Modify the posts count column -->
      <template v-slot:item.posts_count="{ item }">
        <template v-if="item.posts_count === 0">
          <v-chip
            small
            color="primary"
          >
            Unpublished
          </v-chip>
        </template>
        <template v-else>
          <v-chip
            small
            :color="item.posts_count === item.expected_posts ? 'green' : 'orange'"
            class="white--text"
          >
            {{ item.posts_count }} / {{ item.expected_posts }} published
          </v-chip>
        </template>
      </template>

      <!-- Modify the total followers column -->
      <template v-slot:item.followers="{ item }">
        <span :title="item.followers">
          {{ nFormatter(item.followers) }}
        </span>
      </template>

      <!-- Modify the total likes column -->
      <template v-slot:item.total_likes="{ item }">
        <span :title="item.total_likes">
          {{ nFormatter(item.total_likes) }}
        </span>
      </template>

      <!-- Modify the total comments column -->
      <template v-slot:item.total_comments="{ item }">
        <span :title="item.total_comments">
          {{ nFormatter(item.total_comments) }}
        </span>
      </template>

      <!-- Modify the total views column -->
      <template v-slot:item.total_views="{ item }">
        <span :title="item.total_views">
          {{ nFormatter(item.total_views) }}
        </span>
      </template>

      <!-- Modify the total shares column -->
      <template v-slot:item.total_shares="{ item }">
        <span :title="item.total_shares">
          {{ nFormatter(item.total_shares) }}
        </span>
      </template>

      <!-- Modify the total saves column -->
      <template v-slot:item.total_saves="{ item }">
        <span :title="item.total_saves">
          {{ nFormatter(item.total_saves) }}
        </span>
      </template>

      <!-- Modify the average engagement column -->
      <template v-slot:item.average_engagement="{ item }">
        <span :title="item.average_engagement * 100">
          {{ (item.average_engagement * 100).toFixed(2) }}%
        </span>
      </template>

      <!-- Modify the audience credibility column -->
      <template v-slot:item.audience_credibility="{ item }">
        <span
          v-if=item.audience_credibility
          :title="item.audience_credibility * 100"
        >
          {{ (item.audience_credibility * 100).toFixed(2) }}%
        </span>
        <span v-else>
          -
        </span>
      </template>

      <!-- Modify the actions column -->
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end">
          <v-btn
            v-if="module !== 'influencerGroups'"
            small
            depressed
            color="primary"
            @click="redirectToPage(computedPostsRouteName, item)"
          >
            Posts

            <v-icon right>
              fullscreen
            </v-icon>
          </v-btn>

          <!-- Show menu options -->
          <v-menu
            v-if="overview.canUserWrite"
            transition="slide-y-transition"
            :close-on-content-click="false"
            offset-y
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-3"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <!-- Show the menu options -->
            <v-list width="160" dense>
              <!-- Show the edit button -->
              <v-list-item
                v-if="module === 'campaignTracking'"
                @click="showEditDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Edit Influencer
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the add post button -->
              <v-list-item
                v-if="module === 'campaignTracking'"
                @click="$emit('triggerAddPost', item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Add a post
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the add story button -->
              <v-list-item
                v-if="item.platform === 'instagram' && module === 'campaignTracking'"
                @click="showStoryDialog(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Add a story
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the view posts button -->
              <v-list-item
                v-if="module !== 'influencerGroups'"
                @click="redirectToPage(computedPostsRouteName, item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    View posts
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the view stories button -->
              <v-list-item
                v-if="item.platform === 'instagram' && module === 'campaignTracking'"
                @click="redirectToPage(computedStoriesRouteName, item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    View stories
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the delete button -->
              <v-list-item
                v-if="module !== 'mentionTracking'"
                @click="() => { selectedItems = [item]; shouldShowDeleteDialog = true; }"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Remove Influencer
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>

    <!-- Show the add story dialog -->
    <add-story
      v-model="shouldShowAddStoryDialog"
      :module="module"
      :overview="overview"
    />

    <!-- Show the delete confirmation dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      :persistent="isMakingRequest"
      max-width="400"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <v-icon dark class="mr-3">
            delete
          </v-icon>

          Are you sure?
        </v-card-title>

        <v-card-text class="pt-4">
          You are about to remove {{ selectedItems.length }} {{ selectedItems.length > 1 ? "influencers" : "influencer" }}.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest"
            @click="handleDelete"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show an attach post dialog -->
    <v-dialog
      v-model="shouldShowEditDialog"
      :persistent="isMakingEditRequest"
      max-width="450"
    >
      <v-card v-if="selectedItem">
        <v-card-title class="primary white--text">
          Edit Influencer
        </v-card-title>

        <v-card-text class="pt-4">
          <p>
            Enter the expected number of posts for this influencer.
          </p>

          <v-text-field
            v-model="editForm.expectedPosts"
            color="primary"
            label="Expected Posts"
            type="number"
            :hide-details="!$v.editForm.expectedPosts.$anyError"
            :error-messages="$v.editForm.expectedPosts.$anyError ? ['Invalid value'] : null"
            @blur="$v.editForm.expectedPosts.$touch"
            outlined
            dense
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="pb-4 pr-6">
          <v-spacer />

          <v-btn
            depressed
            color="primary"
            :disabled="isMakingEditRequest"
            :loading="isMakingEditRequest"
            @click="handleEditPost"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template v-if="isAuthenticated">
      <influencer-groups-search
        v-model="shouldShowInfluencerGroupsForm"
        :influencers="selectedItems"
      />

      <campaign-tracking-search
        v-model="shouldShowCampaignTrackingForm"
        :influencers="selectedItems"
      />

      <social-sentiments-form
        v-model="shouldShowSocialSentimentsForm"
        :influencers="selectedItems"
      />

      <audience-overlap-form
        v-model="shouldShowAudienceOverlapForm"
        :influencers="selectedItems"
      />
    </template>
  </div>
</template>

<script>
// Import vuetify component
import { VSlideGroup } from 'vuetify/lib'

// Import helper functions
import { required, minValue, maxValue, numeric } from "vuelidate/lib/validators"

// Import children components
const InfluencerGroupsSearch = () => import(/* webpackChunkName: "influencer-groups-search" */ "@/components/crm/influencer-groups/Search.vue")
const CampaignTrackingSearch = () => import(/* webpackChunkName: "campaign-tracking-search" */ "@/components/crm/campaign-tracking/Search.vue")
const SocialSentimentsForm = () => import(/* webpackChunkName: "social-sentiments-form-component" */ "@/components/social-sentiments/Form.vue")
const AudienceOverlapForm = () => import(/* webpackChunkName: "audience-overlap-form-component" */ "@/components/audience-overlap/Form.vue")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/components/crm/PlatformSelector.vue")
const AddStory = () => import(/* webpackChunkName: "crm-add-story" */ "@/components/crm/AddStory")

const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip")
const StatusChip = () => import(/* webpackChunkName: "status-chip" */ "@/blocks/common/StatusChip")

// Export the SFC
export default {
  // Name of the component
  name: "InfluencersTable",

  // Register children components
  components: {
    VSlideGroup,

    InfluencerGroupsSearch,
    CampaignTrackingSearch,
    SocialSentimentsForm,
    AudienceOverlapForm,

    PlatformSelector,
    ProfileChip,
    StatusChip,
    AddStory
  },

  // Accept incoming data from parent
  props: {
    overview: {
      type: Object,
      required: true
    },

    module: {
      type: String,
      required: true,
    },

    shouldShowSelect: {
      type: Boolean,
      required: false,
      default: false
    },

    shouldShowActions: {
      type: Boolean,
      required: false,
      default: false
    },

    shouldShowAddToGroup: {
      type: Boolean,
      required: false,
      default: false
    },

    shouldShowAddToCampaign: {
      type: Boolean,
      required: false,
      default: false
    },

    shouldShowSocialSentimentsButton: {
      type: Boolean,
      required: false,
      default: false
    },

    shouldShowAudienceOverlapButton: {
      type: Boolean,
      required: false,
      default: false
    },

    shouldShowUnpublishedFilter: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  // Define local data variables
  data: () => ({
    // Whether or not to show the add-story dialog
    shouldShowAddStoryDialog: false,
    shouldShowAudienceOverlapForm: false,
    shouldShowInfluencerGroupsForm: false,
    shouldShowCampaignTrackingForm: false,
    shouldShowSocialSentimentsForm: false,

    // The selected items to be deleted
    selectedItem: null,
    selectedItems: [],
    shouldShowDeleteDialog: false,

    // Whether or not is making a request
    isMakingRequest: false,

    // Regarding editing an influencer
    isMakingEditRequest: false,
    shouldShowEditDialog: false,
    editForm: {
      expectedPosts: null,
    },

    // The dropdown option values
    sortOptions: [
      {
        text: "Recent",
        value: "recent"
      },
      {
        text: "Oldest",
        value: "oldest"
      },
      {
        text: "Least Liked",
        value: "least_liked"
      },
      {
        text: "Most Liked",
        value: "most_liked"
      },
      {
        text: "Least Commented",
        value: "least_commented"
      },
      {
        text: "Most Commented",
        value: "most_commented"
      }
    ],
    // The key value pair for sort options
    sortMap: {
      recent: {
        sortBy: "posted_at",
        sortOrder: "desc"
      },

      oldest: {
        sortBy: "posted_at",
        sortOrder: "asc"
      },

      least_liked: {
        sortBy: "likes",
        sortOrder: "asc"
      },

      most_liked: {
        sortBy: "likes",
        sortOrder: "desc"
      },

      least_commented: {
        sortBy: "comments",
        sortOrder: "asc"
      },

      most_commented: {
        sortBy: "comments",
        sortOrder: "desc"
      }
    },

    // Unpublished filter value
    publishedStatusOptions: [
      {
        text: "All",
        value: null
      },
      {
        text: "Published",
        value: "yes"
      },
      {
        text: "Unpublished",
        value: "no"
      }
    ],

    // Module and API endpoint map
    moduleMap: {
      mentionTracking: "mention-tracking",
      campaignTracking: "campaign-tracking",
      influencerGroups: "influencer-groups",
      influencerOutreach: "influencer-outreach"
    },
  }),

  // Define readonly computable variables
  computed: {
    /**
     * Whether or not the user is authenticated
     *
     * @returns {Boolean}
     */
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"]
    },

    /**
     * Get the query object for the influencers
     *
     * @returns {Object}
     */
    query() {
      return this.$store.getters[`${this.module}/influencersQueryById`](this.overview.model.id)
    },

    /**
     * Get the current page number
     *
     * @returns {Number}
     */
    currentPage: {
      get() {
        return this.query.pagination.page
      },

      set(value) {
        this.$store.dispatch(`${this.module}/updateInfluencersQuery`, {
          id: this.overview.model.id,
          query: {
            ...this.query,
            pagination: {
              ...this.query.pagination,
              page: value
            }
          }
        })

        this.fetchInfluencers()
      }
    },

    /**
     * Get the current per page value
     *
     * @returns {Number}
     */
    perPage: {
      get() {
        return this.query.pagination.perPage
      },

      set(value) {
        this.$store.dispatch(`${this.module}/updateInfluencersQuery`, {
          id: this.overview.model.id,
          query: {
            ...this.query,
            pagination: {
              ...this.query.pagination,
              perPage: value
            }
          }
        })

        this.fetchInfluencers()
      }
    },

    /**
     * Get the search query
     */
    searchQuery: {
      get() {
        return this.query.search
      },

      set(value) {
        // Only update if the value is different
        if (this.query.search === value) return

        this.$store.dispatch(`${this.module}/updateInfluencersQuery`, {
          id: this.overview.model.id,
          query: {
            ...this.query,
            search: value
          }
        })
      },
    },

    /**
     * Get the query published status
     */
    publishedStatus: {
      get() {
        return this.query?.publishedStatus || null
      },

      set(value) {
        // If the value is same
        if (this.query.publishedStatus === value) return

        this.$store.dispatch(`${this.module}/updateInfluencersQuery`, {
          id: this.overview.model.id,
          query: {
            ...this.query,
            publishedStatus: value
          }
        })
      }
    },

    /**
     * Get the response object for the influencers
     *
     * @returns {Object}
     */
    response() {
      return this.$store.getters[`${this.module}/influencersResponseById`](this.overview.model.id)
    },

    /**
     * Get the selected platform
     *
     * @returns {String}
     */
    selectedPlatform() {
      return this.$store.getters[`${this.module}/selectedPlatformById`](this.overview.model.id)
    },

    /**
     * Get all the table header items
     *
     * @returns {Array}
     */
    tableHeaders() {
      return [
        {
          text: "",
          value: "platform",
          sortable: false,
        },
        {
          text: "Influencer",
          value: "username",
          sortable: false
        },
        {
          text: "Posts",
          value: "posts_count",
          sortable: false,
          condition: this.module !== "influencerGroups"
        },
        {
          text: "Followers",
          value: "followers",
          sortable: false,
          condition: this.tableItems.some((item) => item.followers > 0)
        },
        {
          text: "Likes",
          value: "total_likes",
          sortable: false,
          condition: this.tableItems.some((item) => item.total_likes > 0)
        },
        {
          text: "Dislikes",
          value: "total_dislikes",
          sortable: false,
          condition: this.tableItems.some((item) => item.total_dislikes > 0)
        },
        {
          text: "Views",
          value: "total_views",
          sortable: false,
          condition: this.tableItems.some((item) => item.total_views > 0)
        },
        {
          text: "Comments",
          value: "total_comments",
          sortable: false,
          condition: this.tableItems.some((item) => item.total_comments > 0)
        },
        {
          text: "Shares",
          value: "total_shares",
          sortable: false,
          condition: this.tableItems.some((item) => item.total_shares > 0)
        },
        {
          text: "Saves",
          value: "total_saves",
          sortable: false,
          condition: this.tableItems.some((item) => item.total_saves > 0)
        },
        {
          text: "Avg Engagement",
          value: "average_engagement",
          sortable: false,
          condition: this.tableItems.some((item) => item.average_engagement > 0)
        },
        {
          text: "Audience Credibility",
          value: "audience_credibility",
          sortable: false,
          condition: this.tableItems.some((item) => item.audience_credibility > 0)
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "right",
          condition: this.shouldShowActions === true
        }
      ].filter((item) => typeof item.condition === "boolean" ? item.condition : true)
    },

    /**
     * Get the table items array for influencers
     *
     * @returns {Array}
     */
    tableItems() {
      return (this.response?.data || []).map((item) => ({
        ...item,
        average_engagement: (((item.likes || 0) + (item.comments || 0) + (item.shares || 0) + (item.saves || 0)) / item.posts_count) / item.followers
      }))
    },

    /**
     * Get the computed posts route name
     *
     * @returns {String}
     */
    computedPostsRouteName() {
      // Create a map for route names
      const map = {
        campaignTracking: "CampaignTrackingPosts",
        mentionTracking: "MentionTrackingPosts",
      }

      // Respond with the route name
      return map[this.module]
    },

    /**
     * Get the computed stories route name
     *
     * @returns {String}
     */
    computedStoriesRouteName() {
      // Create a map for route names
      const map = {
        campaignTracking: "CampaignTrackingStories",
      }

      // Respond with the route name
      return map[this.module]
    },

    /**
     * Whether or not to show the influencer groups form
     *
     * @return {Boolean}
     */
    isInfluencerGroupsAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.influencerGroups)
    },

    /**
     * Whether or not to show the campaign tracking form
     *
     * @return {Boolean}
     */
    isCampaignTrackingAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.campaignTracking)
    },

    /**
     * Whether or not to show the social sentiments form
     *
     * @return {Boolean}
     */
    isSocialSentimentsAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.socialSentiments)
    },

    /**
     * Whether or not to show the audience overlap form
     *
     * @return {Boolean}
     */
    isAudienceOverlapAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.audienceOverlap)
    },

    /**
     * Whether or not to show the content discovery form
     *
     * @return {Boolean}
     */
    isContentDiscoveryAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.actionContentDiscovery)
    },

    /**
     * Whether or not to show the competitor check form
     *
     * @return {Boolean}
     */
    isCompetitorCheckAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.competitorCheck)
    },
  },

  // Define vuelidate validators
  validations: {
    editForm: {
      expectedPosts: {
        required,
        numeric,
        minValue: minValue(1),
        maxValue: maxValue(1000)
      },
    }
  },

  // Set watchers for local data
  watch: {
    selectedPlatform: {
      handler() {
        // Ask to fetch the data from the server again
        this.fetchInfluencers()
      }
    },

    publishedStatus: {
      handler() {
        // Ask to fetch the data form the server again
        this.fetchInfluencers()
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     * Search for influencers
     *
     * @returns {void}
     */
    async fetchInfluencers() {
      // If making a request, stop further execution
      if (this.isMakingRequest) return

      // Show a loader
      const loaderId = Symbol("InfluencersTable/fetchInfluencers")
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Try making a network request
      try {
        // Define the query params
        const queryParams = new window.URLSearchParams(
          Object.entries({
            page: this.query.pagination.page,
            per_page: this.query.pagination.perPage,

            platform: this.selectedPlatform,
            search: this.query.search,

            publishedStatus: this.query.publishedStatus,

            ...this.sortMap[this.sortBy]
          }).filter(([key, value]) => Boolean(value))
        )

        // Use helper function
        const response = await axios(`/api/${this.moduleMap[this.module]}/${this.overview.model.id}/influencers?${queryParams}`)

        // Update the response object
        this.$store.dispatch(`${this.module}/updateInfluencersResponse`, {
          id: this.overview.model.id,
          response: response.data
        })
      }
      // Catch an error
      catch (error) {
        // Show a toast
        this.$store.dispatch("toasts/add", { text: "Failed to fetch influencers!" })

        // Log it
        logger({ type: "InfluencersTable/fetchInfluencers", error })
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
      }
    },

    /**
     * Delete the selected items
     *
     * @returns {void}
     */
    async handleDelete() {
      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true
      this.$emit("loading", true)

      // Make the network request
      try {
        // Use helper function
        await axios({
          url: `/api/${this.moduleMap[this.module]}/${this.overview.model.id}/influencers`,
          method: "DELETE",
          data: {
            models: this.selectedItems.map((item) => item.id)
          }
        })
      }
      // Catch an error
      catch (error) {
        // Log the error
        logger({ type: "CRM/InfluencersTable/Delete Error", error })

        // Show a toast
        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      }

      // Ask to refresh the data
      this.$emit("refresh")

      // Hide the loader
      this.$store.dispatch("loaders/remove", loaderId)
      this.isMakingRequest = false
      this.$emit("loading", false)

      // Reset the selected items
      this.selectedItems = []

      // Hide the dialog
      this.shouldShowDeleteDialog = false
    },

    /**
     * Show the add story dialog
     *
     * @param {Object} influencer
     * @returns {void}
     */
    showStoryDialog(influencer) {
      // Set the influencer
      this.$store.dispatch(`${this.module}/updateSelectedInfluencer`, {
        id: this.overview.model.id,
        value: influencer
      })

      // Show the dialog
      this.shouldShowAddStoryDialog = true
    },

    /**
     * Redirect to the page
     *
     * @param {String} routeName
     */
    redirectToPage(routeName, selectedInfluencer) {
      // Set the selected influencer
      this.$store.dispatch(`${this.module}/updateSelectedInfluencer`, {
        id: this.overview.model.id,
        value: selectedInfluencer
      })

      // Redirect to the page
      this.$router.push({
        name: routeName,
        params: {
          id: this.overview.model.id
        }
      })
    },

    /**
     * Show a dialog to the user
     *
     * @param {Object} item
     * @returns {void}
     */
    showEditDialog(item) {
      // Update predefined values
      this.editForm = {
        expectedPosts: item.expected_posts
      }

      // Mark the object as selected
      this.selectedItem = item

      // Show the dialog
      this.shouldShowEditDialog = true
    },

    /**
     * Make a network request to save these values
     *
     * @returns {void}
     */
    async handleEditPost() {
      // Validate the form
      await this.$v.editForm.$touch()

      // If it's not valid
      if (this.$v.editForm.$anyError) {
        // Show a message
        this.$store.dispatch("toasts/add", { text: "Please enter valid numeric values" })

        // Stop further execution
        return
      }

      // Show a loader
      this.isMakingEditRequest = true

      // Try making a network request
      try {
        await axios({
          url: `/api/${this.moduleMap[this.module]}/${this.overview.model.id}/influencers/${this.selectedItem.id}`,
          method: "PUT",
          data: {
            expected_posts: this.editForm.expectedPosts
          }
        })

        // If succeeded, show a message
        this.$store.dispatch("toasts/add", { text: "Value updated!" })

        // Hide the dialog
        this.shouldShowEditDialog = false

        // Refresh the data
        this.fetchInfluencers()
      }
      // Catch an error
      catch (error) {
        // Log the error
        logger({ type: "InfluencersTable/Influencers Update Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      }
      // Nonetheless
      finally {
        this.isMakingEditRequest = false
      }
    },
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // If the query value is not set
    if (!this.query) {
      // Set the default query value
      this.$store.dispatch(`${this.module}/updateInfluencersQuery`, {
        id: this.overview.model.id,
        query: {
          pagination: {
            page: 1,
            perPage: 10
          },

          sortBy: "most_liked"
        }
      })
    }

    // Ask to fetch the data from the server again
    this.fetchInfluencers()
  }
}
</script>

<style lang="stylus">
.influencers-data-table thead tr th
  white-space nowrap !important
</style>
